module.exports = {
  order_action_types: {
    cancel: "cancel",
    close: "close",
    open: "open"
  },
  order_financial_statuses: {
    authorized: "authorized",
    pending: "pending",
    paid: "paid",
    refunded: "refunded",
    voided: "voided"
  },
  order_fulfillment_statuses: {
    shipped: "shipped",
    unshipped: "unshipped",
    restocked: "restocked",
    zasilkovna: "zasilkovna"
  },
  allowed_currencies: { // Also in src/applications/Shared/constants/other.js
    AUD: "AUD",
    BRL: "BRL",
    CAD: "CAD",
    CNY: "CNY",
    CZK: "CZK",
    DKK: "DKK",
    EUR: "EUR",
    HKD: "HKD",
    HUF: "HUF",
    INR: "INR",
    ILS: "ILS",
    JPY: "JPY",
    MYR: "MYR",
    MXN: "MXN",
    TWD: "TWD",
    NZD: "NZD",
    NOK: "NOK",
    PHP: "PHP",
    PLN: "PLN",
    GBP: "GBP",
    RUB: "RUB",
    SGD: "SGD",
    SEK: "SEK",
    CHF: "CHF",
    THB: "THB",
    USD: "USD",
  },
  notifications_severities: {
    normal: "normal", // Grey
    warning: "warning", // Yellow
    danger: "danger", // Red
    success: "success", // Green
  },
  default_language: "en",
  default_currency: "USD",
  max_allowed_projects_per_user: 10,
  default_variant_title: "Default Title",
  max_number_of_popular_products: 10,
  default_email: "info@ulsemo.com",
  default_email_password: "sitpkrbhhfoqqhnb",
  marketing_email_app_password: "lcbzoeqkcplfybuu",
  paypal_payout_test_email: "josef.reischig@reischigindustries.com",
  marketing_email: "marketing@ulsemo.com",
  default_email_provider: "Gmail",
  test_project_id: "test-project-1234"
}
